import React, { useState, useEffect, useRef } from 'react';
import {
  StepContainer,
  AddProcessContainer,
  BoxInput,
  CkEditorComtainer,
  AllInputsContainer,
  StepsContainer,
  ProcessActionsContainer,
  ProcessStepButton,
  LineThrough,
  MoreOptionListItem,
  MoreOptionList,
  PopoverContainer
} from './styled';
import { Breadcrumb, Button, Input, Drawer, Popover, Modal } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Ckeditor from '../../../components/CKeditor/Ckeditor';
import { useDispatch, useSelector } from 'react-redux';
import { enableAddStep } from '../../../features/step/stepSlice';
import { useNavigate, Link , useParams} from 'react-router-dom';
import usePatch from 'hooks/usePatch';
import Comments from "./Comments";
import { toast } from 'react-hot-toast';
import { useReactToPrint } from 'react-to-print';
import CommonModal from '../../../components/CommonModal/CommonModal';
import { getStepDescription } from 'utils/helper';
import Styles from "./Style.module.css"
import useGet from 'hooks/useGet';

const { TextArea } = Input;

const Openprocess = () => {
  const { mutateAsync: CommonPatch } = usePatch();
  const dispatch = useDispatch();
  const [isCancelModalOpen, setShowModal] = useState(false);
  
  const [process, setSelectedProcess] = useState(null);
  const contentToPrint = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [processId, setProcessId] = useState('');
  const [navigateTo, setNavigateTo] = useState(undefined);
  const [stepDescriptions, setStepDescriptions] = useState([]);

  const handlePrint = useReactToPrint({
    documentTitle: "Process Detail",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });
  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [clickedIndex, setClickedIndex] = useState(null);
  const [checkList, setCheckList] = useState(false);
  const [stepIds, setStepIds] = useState([]);
  const [stepPayload, setStepPayload] = useState(null);
  const [moreOpt, setMoreOpt] = useState(false);
  const userRole = localStorage.getItem('userRole');
  const [open, setOpen] = useState(false);
  const [reviewedStep, setReviewedStep] = useState([]);
  const { mutateAsync: ProcessGet } = useGet();
  
  const { groupList, processList, folderList } = useSelector((state) => state.group);
  const sortedSteps = process?.step?.slice().sort((a, b) => a.id - b.id);
  const [groupName, setGroupName] = useState('');
  const [groupId, setGroupId] = useState('');

  const [folderName, setFolderName] = useState('');
  const [folderId, setFolderId] = useState('');

  const handleUpdateStepClick = (id) => {
    const index = process?.step?.findIndex(val => val.id === id)
    setClickedIndex(index);
    dispatch(enableAddStep());

    if (!reviewedStep.includes(id)) {
      setReviewedStep(prev => [...prev, id])
    }
  };
  const processById = (id) => {

    ProcessGet({
      url: `process/${id}`,
      type: 'details',
      token: true
    })
      .then((res) => {
        setSelectedProcess(res);
        setStepIds(res?.step?.filter((i) => i.isCompleted).map(i => i.id));
        setProcessAdditionalDetails(res);
        setStepDescriptions(res?.step?.map((i) => stripHtmlTags(i?.stepDescription).split('\n')[0]));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  useEffect(() => {
    processById(id);
  }, [id, processList, groupList, folderList]);

  useEffect(() => {
    if (clickedIndex !== null) {
      setStepDescriptions((prevDescriptions) => {
        const updatedDescriptions = [...prevDescriptions];
        updatedDescriptions[clickedIndex] = stripHtmlTags(process?.step[clickedIndex]?.stepDescription).split('\n')[0];
        return updatedDescriptions;
      });
    }
  }, [clickedIndex, process]);

  function showModal(title, id) {
    setProcessId(id)
    setModalTitle(title);
    setIsModalOpen(true);
  }

  const handleCheckboxChange = (stepId) => {
    setStepIds((prevStepIds) => {
      const updatedStepIds = prevStepIds.includes(stepId) ? prevStepIds.filter((id) => id !== stepId) : [...prevStepIds, stepId];
      const updatedStepStatus = updatedStepIds.map((id) => true);

      const payload = {
        id: updatedStepIds,
        isCompleted: updatedStepStatus
      };

      setStepPayload(payload)
      return updatedStepIds;
    });
  };

  const reviewProcess = () => {
      navigate(`/review-process/${process?.id}`);
  }

  const copy = async (id, type) => {
    try {
      await navigator.clipboard.writeText(id+"_"+type);
      toast.success('Process Copied.');
    }
    catch(err) {
      console.log(err)
    }
  }

  const saveSteps = () => {
    if (!stepPayload) {
      return;
    }
    CommonPatch({
      url: 'step',
      type: 'details',
      payload: stepPayload,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        toast.success('Step Marked Completed!');
        setCheckList(false);
        processById(process?.id)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleEditProcessClick = () => {
    navigate(`/add-process/${process?.id}`);
  };

  const handleOkChecklistModal = () => {
    setCheckList(false);
    setShowModal(false);
  }

  const handleChecklistCancelModal = () => {
    setShowModal(false);
  }


  const handleChecklistCancel = () => {
    setShowModal(true);
  }

  const setProcessAdditionalDetails = (processselected) => {

    if (processList.find((p) => p?.id === processselected?.id)) {
      setGroupName("");
      setGroupId("");
      setFolderName("");
      setFolderId("");
    }else {
      groupList.forEach((group) => {
        if (group.proces?.find((p) => p?.id === processselected?.id)) {

          setGroupName(group?.name);
          setGroupId(group?.id);
          setFolderName("");
          setFolderId("");
        }else {
          group.folder?.forEach((folder) => {
            if (folder.process?.find((p) => p?.id === processselected?.id)) {
              setGroupName(group?.name);
              setGroupId(group?.id);
              setFolderName(folder?.name);
              setFolderId(folder?.id);
            }
          });
        }
      });

      folderList.forEach((folder) => {
        if (folder.process?.find((p) => p?.id === processselected?.id)) {

          setGroupName("");
          setGroupId("");
          setFolderName(folder?.name);
          setFolderId(folder?.id);
        }
      });
    }
  }

  return (process &&
    <>
      <div ref={contentToPrint} style={{ display: "none" }} className="print-process">
        <h1>{process?.name}</h1>
        <label style={{ fontSize: 24, marginBottom: 10, textDecoration: 'underline', display: 'inline-block', fontWeight: 'bold' }}>Description:</label>
        <div style={{ fontSize: 26, marginBottom: 15 }}>{process?.name}</div>
        <label style={{ fontSize: 24, marginBottom: 10, textDecoration: 'underline', display: 'inline-block', fontWeight: 'bold' }}>Tags:</label>
        <div style={{ fontSize: 26, marginBottom: 15 }}>{process?.tags}</div>
        <label style={{ fontSize: 24, marginBottom: 10, textDecoration: 'underline', display: 'inline-block', fontWeight: 'bold' }}>Steps</label>
        <div className={Styles.steps}>
            <br/>
            <br/>
            {process && process?.step?.slice().sort((a, b) => a.id - b.id)?.map((i, index) => (
                <div key={index}>
                    <div>
                        <Input
                            value={`${index + 1}. ${getStepDescription(i.stepDescription)}`}
                            type="text"
                            placeholder={`Add Step ${index + 1}`}
                            style={{ backgroundColor: '#ccc' }}
                            readOnly
                        />
                    </div>
                    {checkList && (
                      <div>
                        <Input type="checkbox" style={{ height: 15, width: 15 }} checked={stepIds.includes(i.id)} />
                      </div>
                    )}
                    <div className={Styles.stepDiscription} dangerouslySetInnerHTML={{ __html:  i?.stepDescription}}></div>
                </div>
            ))}
          </div>
      </div>
      <AddProcessContainer>
        {checkList && <ProcessStepButton>
          <Button onClick={() => saveSteps()}>Step Completed</Button>
          <Button onClick={() => handleChecklistCancel()}>Cancel</Button>
        </ProcessStepButton>}
        <StepsContainer>
        <Breadcrumb>
          <span>
              <Link to="/home">Home</Link>
              <RightOutlined /> 

              {/* Check if groupId is present */}
              {groupId && (
                <>
                  <Link to={`/group/${groupId}`}>{groupName}</Link>
                  <RightOutlined />
                </>
              )}

              {/* Check if folderId is present */}
              {folderId && (
                <>
                  <Link to={ groupId?`/group/${groupId}/folder/${folderId}` : `/folder/${folderId}`}>{folderName}</Link>
                  <RightOutlined />
                </>
              )}
              
              {/* Render the process name if present */}
              {process?.name ? process?.name : ''}
          </span>
        </Breadcrumb>
          <AllInputsContainer className="okokokok printable-element">
            <BoxInput>
              <label>Name:</label>
              <Input value={process?.name} type="text" placeholder="Enter Process Name" style={{ backgroundColor: '#ccc' }} readOnly />
            </BoxInput>
            <BoxInput>
              <label>Description:</label>
              <TextArea value={process?.description} type="text" rows={2} placeholder="Enter Process Description" style={{ backgroundColor: '#ccc' }} readOnly />
            </BoxInput>
            <BoxInput>
              <label>Tags:</label>
              <TextArea value={process?.tags} type="text" rows={2} placeholder="Tags and Keywords" style={{ backgroundColor: '#ccc' }} readOnly />
            </BoxInput>
            <BoxInput>
              <label>Steps</label>
              {sortedSteps?.map((i, index) => (

                <StepContainer key={index}>
                  <div>
                    {i.isCompleted && <LineThrough></LineThrough>}
                    {' '}
                    <Input
                      value={`${index + 1}. ${getStepDescription(i.stepDescription)}`}
                      type="text"
                      placeholder={`Add Step ${index + 1}`}
                      onClick={() => handleUpdateStepClick(i.id)}
                      style={{ backgroundColor: '#ccc' }}
                      readOnly
                    />
                  </div>
                  {checkList && (
                    <div>
                      <Input type="checkbox" style={{ height: 15, width: 15 }} onChange={() => handleCheckboxChange(i.id)} checked={stepIds.includes(i.id)} />
                    </div>
                  )}
                </StepContainer>
              ))}
            </BoxInput>
          </AllInputsContainer>
          <ProcessActionsContainer>
            <Button onClick={() => saveSteps()} disabled={true}>
              Review Complete
            </Button>
            {
              (parseInt(userRole) === 4 || parseInt(userRole) === 3 || parseInt(userRole) === 2) && (
                <Button onClick={handleEditProcessClick}>Edit Process</Button>
              )}
            <Button onClick={() => setCheckList(true)}>Run CheckList</Button>
            {
              checkList && <Button onClick={() => {
                handlePrint(null, () => contentToPrint.current);
              }}>Print CheckList</Button>
            }
            <div style={{ position: 'relative',width: '100%'  }}>
              <Popover
                overlayStyle={{ width: '200px' }} 
                overlayInnerStyle={{ padding:'0px' }}
                placement="topLeft"
                content={
                  <PopoverContainer>
                    <MoreOptionList>
                    <MoreOptionListItem onClick={reviewProcess}>Review Process</MoreOptionListItem>
                      <MoreOptionListItem onClick={() => {
                        handlePrint(null, () => contentToPrint.current);
                        setMoreOpt(false);  // Close options after action
                      }}>Print</MoreOptionListItem>
                
                      {parseInt(userRole) < 5 && (
                        <>
                          <MoreOptionListItem onClick={() => copy(id, 'MOVE')}>Move Process</MoreOptionListItem>
                          <MoreOptionListItem onClick={() => copy(id, 'COPY')}>Copy Process</MoreOptionListItem>
                          <MoreOptionListItem onClick={() => {
                            showModal('Process Delete', id);
                            let route = '';
                            if (groupId && folderId) {
                              route = `/group/${groupId}/folder/${folderId}`;
                            } else if (groupId && !folderId) {
                              route = `/group/${groupId}`;
                            } else if (!groupId && folderId) {
                              route = `/folder/${folderId}`;
                            } else {
                              route = `/home`;
                            }
                            setNavigateTo(route);
                          }}>Delete Process</MoreOptionListItem>
                        </>
                      )}
                      
                      <MoreOptionListItem onClick={() => navigate(`/view-process/${id}`)}>View Process details</MoreOptionListItem>
                      <MoreOptionListItem onClick={() => navigate(`/view-markup/${id}`)}>View Markups</MoreOptionListItem>
                      <MoreOptionListItem onClick={() => showModal('ShareProcess', id)}>Share Process</MoreOptionListItem>
                      <MoreOptionListItem onClick={() => {
                        setOpen(true); 
                        setMoreOpt(false);  // Close options after action
                      }}>Comment on Process</MoreOptionListItem>
                    </MoreOptionList>
                  </PopoverContainer>
                }
                trigger="click"  // Use "click" to open the options list on button click
                visible={moreOpt}  // Control visibility of the Popover
                onVisibleChange={setMoreOpt}  // Sync popover visibility with state
              >
                <Button style={{ width: '100%' }}>
                  More Options
                </Button>
              </Popover>
            </div>

          </ProcessActionsContainer>
        </StepsContainer>
        {
        <CkEditorComtainer className="CkEditorComtainer">
          <Ckeditor data={clickedIndex !== null ? process?.step[clickedIndex]?.stepDescription : "" }/>
        </CkEditorComtainer>
        } 
            
      {isCancelModalOpen && (
          <Modal title="Confirm" closable={false} open={true} onOk={handleOkChecklistModal} onCancel={handleChecklistCancelModal}   okText="Yes"
          cancelText="No">
            <p>
              Are you sure you want to leave?
            </p>
          </Modal>
        )}
      </AddProcessContainer>
      <Drawer title="Comments" onClose={() => setOpen(false)} open={open}>
        <Comments id={id}></Comments>
      </Drawer>
      <CommonModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={modalTitle}
        fetchData={()=> {}}
        processId = {processId}
        navigateTo = {navigateTo}
      />
    </>
  );
};

export default Openprocess;
