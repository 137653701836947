/* eslint-disable jsx-a11y/anchor-is-valid */
import { BookOutlined } from '@ant-design/icons';
import { Button, Popover, Table } from 'antd';
import CommonModal from 'components/CommonModal/CommonModal';
import useGet from 'hooks/useGet';
import { PopoverContainer } from 'layout/LoginLayout/Style';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatDate , getProcessReviewDateFormat} from 'utils/helper';
import { getFolderList, getGroupList, getProcessList } from '../../../features/Group/groupslice';
import { AddProcessLink, HeaderMessage, HomeContainer, HomeContent, HomeHeader, TableData } from './Styled';
const GroupFolderProcess = () => {
  const { mutateAsync: GroupListGet } = useGet();

  const { groupId, folderId } = useParams();
  const group = useSelector((state) => state.group.groupList.find((g) => g.id.toString() === groupId));
  const folder = group?.folder.find((f) => f.id.toString() === folderId);
  const [modalTitle, setModalTitle] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedProcessName, setSelectedProcessName] = useState();
  const [processId, setProcessId] = useState();
  const userRole = localStorage.getItem('userRole');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  const handleRightClick = (e, group) => {
    e.preventDefault();
    setPopoverPosition({ top: e.clientY, left: e.clientX });
    setSelectedGroup(group);
    setPopoverVisible(true);
  };

  const showModal = (title, i) => {
    setModalTitle(title);
    setIsModalOpen(true);
    setProcessId(i?.id);
    setSelectedProcessName(i?.name);
  };
  const companyId = localStorage.getItem('companyId');
  const fetchData = () => {
    GroupListGet({
      url: 'group/list/' + companyId,
      type: 'details',
      token: true
    })
      .then((res) => {
        const allGroups = [...(res?.group || []), ...(res?.assignGroup || [])];
        const allFolder = [...(res?.folder || []), ...(res?.assignFolder || [])];
        const allProcesses = [...(res?.process || []), ...(res?.assignProcess || [])];
        dispatch(getProcessList({ processList: allProcesses }));
        dispatch(getFolderList({ folderList: allFolder }));
        dispatch(getGroupList({ groupList: allGroups }));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const copy = async (process, type) => {
    try {
      await navigator.clipboard.writeText(process.id + '_' + type);
      toast.success('Process Copied.');
      setPopoverVisible(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleEditClick = (process) => {
    navigate(`/add-process/${process.id}`);
  };
  
  const handleReviewClick = (process) => {
    navigate(`/review-process/${process.id}`);
  };
  
  const handleOpenClick = (process) => {
    navigate(`/open-process/${process.id}`);
  };
  const columns = [
    {
      title: 'Process Name',
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      render: (text, record) => (
        <div>
          <BookOutlined />
          <span style={{ paddingLeft: 5 }}>{text}</span>
        </div>
      )
    },
    {
      title: 'Date Created',
      dataIndex: 'createdAt',
      sorter: {
        compare: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        multiple: 3
      },
      render: (text) => formatDate(text)
    },
    {
      title: 'Last Update',
      dataIndex: 'updatedAt',
      sorter: {
        compare: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
        multiple: 2
      },
      render: (text) => formatDate(text)
    },
    {
      title: 'Last Review',
      dataIndex: 'lastReview',
      sorter: {
        compare: (a, b) => new Date(a.lastReview) - new Date(b.lastReview),
        multiple: 1
      },
      render: (text, record) => {
          return getProcessReviewDateFormat(record.lastReview, record.isReview);
      }
    }
  ];

  const data = folder?.process.map((i) => ({
    id: i.id,
    name: i.name,
    createdAt: i.createdAt,
    updatedAt: i.updatedAt,
    lastReview: i.reviewDate ?? ''
  }));

  return (
    <>
      <HomeContainer>
        <HomeHeader>
          <HeaderMessage>
            <span>
              <Link to="/home">Home</Link> {' > '} <Link to={`/group/${groupId}`}>{group?.name}</Link>
              {' > '} {folder?.name}
            </span>
          </HeaderMessage>
        </HomeHeader>
        <HomeContent>
          <TableData>
            <Table
              dataSource={data}
              columns={columns}
              rowKey="id"
              onRow={(record) => ({
                onContextMenu: (e) => handleRightClick(e, record)
              })}
            />
            {folder?.process?.map((i) => (
              <div key={"div" +i?.id} style={{ position: 'absolute', left: popoverPosition.left, top: popoverPosition.top }}>
                <Popover
                  key={i?.id}
                  content={
                    <PopoverContainer>
                      <Button onClick={() => handleOpenClick(i)}>Open</Button>
                      {parseInt(userRole) !== 5 && (
                        <>
                          <Button onClick={() => handleEditClick(i)}>Edit</Button>
                          <Button onClick={() => handleReviewClick(i)}>Review</Button>
                          <Button onClick={() => copy(i, 'COPY')}>Copy</Button>
                          <Button onClick={() => copy(i, 'MOVE')}>Move</Button>
                          <Button onClick={() => showModal('Process Delete', i)}>Delete</Button>
                        </>
                      )}
                      <Button onClick={() => navigate(`/create-task?id=${folderId}&processId=${i.id}`)}>Add to Task Manager</Button>
                    </PopoverContainer>
                  }
                  trigger="contextMenu"
                  visible={popoverVisible && selectedGroup?.id === i.id}
                  onVisibleChange={(visible) => setPopoverVisible(visible)}
                  placement="rightTop"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  <div />
                </Popover>
              </div>
            ))}
          </TableData>
          <AddProcessLink>
            <span>
              Create a new process <a onClick={() => showModal('Process')}>here</a>
            </span>
          </AddProcessLink>
        </HomeContent>
      </HomeContainer>

      <CommonModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={modalTitle}
        folderId={folderId}
        fetchData={fetchData}
        processName={selectedProcessName}
        processId={processId}
      />
    </>
  );
};

export default GroupFolderProcess;
