import { Calendar, TreeSelect } from 'antd';
import useGet from 'hooks/useGet';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import './Components/style.css';
import Style from './Components/Style.module.css';
import TaskScheduler from './Components/TaskScheduler';
import { setSelectedGroupDate } from '../../../features/Group/groupslice';
import dayjs from 'dayjs';

const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
const onPanelChange = (value, mode) => {
  console.log(value.format('YYYY-MM-DD'), mode);
};
// Convert Local Time to UTC
function localToUTC(localDateString) {
  // Parse the local time string with moment
  const localTime = moment(localDateString).local();

  // Convert to UTC
  const utcTime = localTime.utc().format('YYYY-MM-DDTHH:mm:ss');

  return utcTime;
}

// Convert UTC to Local Time
function utcToLocal(utcDateString) {
  // Parse the UTC time string with moment
  const utcTime = moment.utc(utcDateString);

  // Convert to local time
  const localTime = utcTime.local().format('YYYY-MM-DDTHH:mm:ss');
  return localTime;
}
export default function TaskManager() {
  const currentTime = moment().valueOf() / 1000;
  const { mutateAsync: TaskListGet } = useGet();
  const companyId = localStorage.getItem('companyId');
  const [tree, setTree] = useState([]);
  const [task, setTask] = useState([]);
  const { groupList, selectedGroup, selectedDate } = useSelector((state) => state.group);
  const [value, setValue] = useState(selectedGroup);
  const [selectedPriorities, setSelectedPriorities] = useState([]);
  const [left, setLeft] = useState(0);
  const [date, setDate] = useState(selectedDate ? selectedDate: getCurrentDate());
  const [loading, setLoading] = useState(false);
  const [scheduleType, setScheduleType] = useState('week');
  const [searchParams] = useSearchParams();
  const queryGroupId = searchParams.get('groupId') || '';
  const dispatch = useDispatch();

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedPriorities((prev) => (checked ? [...prev, value] : prev.filter((priority) => priority !== value)));
  };

  const fetchData = () => {
    // Existing fetchData function logic with additional filtering
    const currentTime7 = moment(date).startOf('day').valueOf() / 1000;
    const currentTime18 = moment(date).endOf('day').valueOf() / 1000;

    TaskListGet({
      url: 'task/' + companyId,
      type: 'details',
      token: true
    })
      .then((res) => {
        res = res.filter((task, index, self) => 
          index === self.findIndex(t => t.id === task.id)
        );
        let filteredTasks = res.filter((task) => selectedPriorities.length === 0 || selectedPriorities.includes(task.priorityType));

        let result = {};
        if (scheduleType === 'month' || scheduleType === 'next7' || scheduleType === 'workweek') {
          result = filteredTasks.reduce((acc, item) => {
            const key = `${item?.group?.id}$$${item?.group?.name}`;
            if (!acc[key]) {
              acc[key] = [
                {
                  ...item,
                  start: new Date(utcToLocal(item.startDate)).toISOString().replace(/T.*$/, ''),
                  end: new Date(utcToLocal(item.endDate)).toISOString().replace(/T.*$/, '')
                }
              ];
            } else {
              acc[key].push({
                ...item,
                start: new Date(utcToLocal(item.startDate)).toISOString().replace(/T.*$/, ''),
                end: new Date(utcToLocal(item.endDate)).toISOString().replace(/T.*$/, '')
              });
            }
            return acc;
          }, {});
        } else {
          result = filteredTasks.reduce((acc, item) => {
            const startTimestamp = new Date(utcToLocal(item.startDate)).getTime() / 1000;
            const endTimestamp = new Date(utcToLocal(item.endDate)).getTime() / 1000;
            const key = `${item?.group?.id}$$${item?.group?.name}`;
            let width = 0;
            let left = 0;
            if (startTimestamp >= currentTime7 && endTimestamp <= currentTime18) {
              width = endTimestamp - startTimestamp;
              left = startTimestamp - currentTime7;
            } else if (startTimestamp < currentTime7 && endTimestamp >= currentTime7 && endTimestamp <= currentTime18) {
              width = endTimestamp - currentTime7;
            } else if (startTimestamp >= currentTime7 && endTimestamp >= currentTime18) {
              width = currentTime18 - startTimestamp;
              left = startTimestamp - currentTime7;
            }
            if (startTimestamp < currentTime18 && width !== 0) {
              if (!acc[key]) {
                acc[key] = [
                  {
                    ...item,
                    startTimestamp,
                    endTimestamp,
                    width,
                    currentTime7,
                    currentTime18,
                    currentTime,
                    left,
                    startTime: moment.utc(item.startDate).local().format('HH:mm:ss'),
                    endTime: moment.utc(item.endDate).local().format('HH:mm:ss'),
                  }
                ];
              } else {
                acc[key].push({
                  ...item,
                  startTimestamp,
                  endTimestamp,
                  width,
                  currentTime7,
                  currentTime18,
                  currentTime,
                  left,
                  startTime: moment.utc(item.startDate).local().format('HH:mm:ss'),
                  endTime: moment.utc(item.endDate).local().format('HH:mm:ss'),
                });
              }
            }
            return acc;
          }, {});
        }
        setTask(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching data:', error);
      });
  };

  const onChange = (newValue) => {
    dispatch(setSelectedGroupDate({selectedGroup: newValue, selectedDate: date}));
    setValue(newValue);
  };

  const onDateChange = (newValue) => {
    dispatch(setSelectedGroupDate({selectedGroup: selectedGroup, selectedDate: newValue.format('YYYY-MM-DD')}));
    setDate(newValue.format('YYYY-MM-DD'));
  };

  function convertToTree(data) {
    let returnData = data?.map((group) => ({
      value: `${group.id}$$${group.name}`,
      title: group.name,
      children: group.folder?.map((folder) => ({
        value: `${folder.id}$$${folder.name}`,
        title: folder.name
      }))
    }));
    return returnData;
  }

  useEffect(() => {
    fetchData();
  }, [selectedPriorities]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const currentTime7 = moment(date).startOf('day').valueOf() / 1000;
    const currentTime18 = moment(date).endOf('day').valueOf() / 1000;
    let val = 0;
    if (currentTime > currentTime7 && currentTime <= currentTime18) {
      val = (currentTime - currentTime7) / 60;
    }
    setLeft(val);

    fetchData();
  }, [date, scheduleType]);

  useEffect(() => {
    setTree(convertToTree(groupList), []);
    if (groupList && groupList.length > 0 && queryGroupId) {
      const group = groupList.find((g) => `${g.id}` === queryGroupId);
      if (group) {
        setValue([`${group.id}$$${group.name}`]);
      }
    }
  }, [groupList, queryGroupId]);

  const wrapperStyle = {
    width: 288,
    border: `1px solid`,
    borderRadius: 10,
    position: 'fixed',
    bottom: 10,
    left: 0
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div className="sidebar">
          <label style={{ color: '#fff', marginTop: 20 }}>Select Group</label>
          <TreeSelect
            showSearch
            style={{ width: '100%', marginTop: 20 }}
            value={value}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Please select"
            allowClear
            multiple
            treeDefaultExpandAll
            onChange={onChange}
            treeData={tree}
          />
          <div className="container" style={{ marginTop: 100 }}>
            <form style={{ height: 300 }}>
              <label style={{ color: '#000', marginTop: 20 }}>Priority</label>
              <div className="form-check d-flex">
                <input className="form-check-input" type="checkbox" id="low" value="low" onChange={handleCheckboxChange} />
                <label className="form-check-label" htmlFor="low">
                  Low
                </label>
              </div>
              <div className="form-check d-flex">
                <input className="form-check-input" type="checkbox" id="medium" value="medium" onChange={handleCheckboxChange} />
                <label className="form-check-label" htmlFor="medium">
                  Medium
                </label>
              </div>
              <div className="form-check d-flex">
                <input className="form-check-input" type="checkbox" id="high" value="high" onChange={handleCheckboxChange} />
                <label className="form-check-label" htmlFor="high">
                  High
                </label>
              </div>
              <div className="form-check d-flex">
                <input className="form-check-input" type="checkbox" id="critical" value="critical" onChange={handleCheckboxChange} />
                <label className="form-check-label" htmlFor="critical">
                  Critical
                </label>
              </div>
            </form>
          </div>

          <div style={wrapperStyle}>
            <Calendar value={dayjs(date)} fullscreen={false} onPanelChange={onPanelChange} onChange={onDateChange} />
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <div className={Style.TaskManager}>
            {!loading && (
              <TaskScheduler value={value} date={date} task={task} type={scheduleType} left={left} fetchData={fetchData}></TaskScheduler>
            )}
          </div>
          <div className={Style.TaskScheduleType}>
            <div
              className={scheduleType === 'week' ? Style.selected : null}
              onClick={() => {
                setLoading(true);
                setScheduleType('week');
              }}
            >
              Week
            </div>
            <div
              className={scheduleType === 'next7' ? Style.selected : null}
              onClick={() => {
                setLoading(true);
                setScheduleType('next7');
              }}
            >
              Next 7 days
            </div>
            <div
              className={scheduleType === 'workweek' ? Style.selected : null}
              onClick={() => {
                setLoading(true);
                setScheduleType('workweek');
              }}
            >
              Work Week
            </div>
            <div
              className={scheduleType === 'month' ? Style.selected : null}
              onClick={() => {
                setLoading(true);
                setScheduleType('month');
              }}
            >
              Month
            </div>
            <div
              className={scheduleType === 'schedule' ? Style.selected : null}
              onClick={() => {
                setLoading(true);
                setScheduleType('schedule');
              }}
            >
              Schedule
            </div>
            <div
              className={scheduleType === 'board' ? Style.selected : null}
              onClick={() => {
                setLoading(true);
                setScheduleType('board');
              }}
            >
              Board
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
