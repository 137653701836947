import React, { useState } from 'react';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import { useSelector, useDispatch } from 'react-redux';
import { setStepDescription } from '../../features/CKeditor/ckeditorslice';
import { FileUploadPlugin } from './file-upload-plugin';
import { ImageEditorPlugin } from './image-editor-plugin';
import { Modal } from 'antd';
import FilerobotImageEditor, { TABS, TOOLS } from "react-filerobot-image-editor"
import axios from 'axios';

import {
	ClassicEditor,
	Autoformat,
	Bold,
	Italic,
	BlockQuote,
	CloudServices,
	Essentials,
	Heading,
	Image,
	ImageCaption,
	ImageStyle,
	ImageToolbar,
	ImageUpload,
  ImageResize,
  LinkImage,
	PictureEditing,
	Indent,
	Link,
	List,
	Mention,
	Paragraph,
	PasteFromOffice,
	Table,
	TableToolbar,
	TextTransformation,
  MediaEmbed,
  FileRepository,
  Context,
  ContextWatchdog
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

const Ckeditor = ({ data }) => {
  const { REACT_APP_DETAILS_URL } = process.env;
  
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [editorInstance, setEditorInstance] = useState(null); 
  // Function to open modal with Filerobot editor
  const openImageEditor = (imageUrl) => {
    setImageUrl(imageUrl)
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setImageUrl(undefined)
    setIsModalVisible(false);
  };
  
  const isAddStepEnabled = useSelector((state) => state.features.isAddStepEnabled);

  const editorConfiguration = {
    // You can pass additional configuration to your plugin if needed
    imageEditorConfig: {
      openImageEditor, // Pass openImageEditor function to the plugin
    },
    image: {
      toolbar: [
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        '|',
        'toggleImageCaption',
        'imageTextAlternative',
        'resizeImage',
        'imageEditor'
      ],

      insert: {
        // This is the default configuration, you do not need to provide
        // this configuration key if the list content and order reflects your needs.
        integrations: [ 'upload', 'assetManager', 'url' ]
      }
    },

    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
    plugins: [
      Essentials,
      Autoformat,
      Bold,
      Italic,
      BlockQuote,
      CloudServices,
      Heading,
      Image,
      ImageCaption,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      Indent,
      Link,
      List,
      Mention,
      Paragraph,
      PasteFromOffice,
      PictureEditing,
      Table,
      TableToolbar,
      TextTransformation, ImageResize, LinkImage,
      MediaEmbed,
      FileRepository,
      FileUploadPlugin,
      ImageEditorPlugin
    ],
    toolbar: [
      'undo',
      'redo',
      '|',
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'link',
      'uploadImage',
      'insertTable',
      'blockQuote',
      'mediaEmbed',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'outdent',
      'indent',
    ],
  };
  // Transforms base64 data into a blob object.
  //
  // @param {String} The base64 data to be transformed.
  // @returns {Blob} Blob object representing given base64 data.
  const base64ToFile = (editedImageObject ) =>{
    const [ type, data ] = editedImageObject.imageBase64.split( ',' );
    const byteCharacters = atob( data );
    const byteArrays = [];

    for ( let offset = 0; offset < byteCharacters.length; offset += 512 ) {
      const slice = byteCharacters.slice( offset, offset + 512 );
      const byteNumbers = new Array( slice.length );

      for ( let i = 0; i < slice.length; i++ ) {
        byteNumbers[ i ] = slice.charCodeAt( i );
      }

      byteArrays.push( new Uint8Array( byteNumbers ) );
    }
    const filename = getFilename(editedImageObject.fullName);

    const blobData =  new Blob( byteArrays, { type } );
    return new File([blobData], filename, { type: editedImageObject.mimeType});
  }
  
  const getFilename = (input) => {
    const regex = /image-\d+-\d+-(.+)/;
    const match = input.match(regex);
    return match ? match[1] : null;
  };

  const uploadImage = async (editedImageObject) => {
    return new Promise((resolve, reject) => {
      const file = base64ToFile(editedImageObject);
      const formData = new FormData();
      formData.append('image', file); // Append the file to the FormData
      axios.post(`${REACT_APP_DETAILS_URL}process/add-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
            const imageUrl = response?.data?.result?.url;
              resolve({
                imageUrl: imageUrl
              });
        })
        .catch((error) => {
              reject(error);
            });
      });
  };

  return (
    <div>
        <CKEditorContext
        context={ Context }
        contextWatchdog={ ContextWatchdog }
        >
          <CKEditor
            disabled={isAddStepEnabled}
            editor={ClassicEditor}
            config={editorConfiguration}
            data={data}
            onReady={(editor) => {
              setEditorInstance(editor); // Store the editor instance
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              dispatch(setStepDescription(data));
            }}
            onBlur={(event, editor) => {}}
            onFocus={(event, editor) => {}}
          />
      </CKEditorContext>
      {imageUrl && 
      <Modal
        title=""
        open={isModalVisible}
        onCancel={handleModalClose}
        width={'auto'}    // Adjust width for better experience
        footer={null}
        closable ={false}
      >
        <div style={{ height: "80vh", width: "100%" }}>
          <FilerobotImageEditor
          defaultSavedImageQuality={1}
          useBackendTranslations={false}
          source={imageUrl}
          onSave={async (editedImageObject, designState) =>
          {
            const response = await uploadImage(editedImageObject)
              // Assuming you have a way to find and replace the image
            const currentData = editorInstance.getData(); // Get current content
            const newData = currentData.replace(decodeURI(imageUrl), response.imageUrl); // Replace old image URL with new image URL
              // Set the new content to CKEditor
            editorInstance.setData(newData); // Refresh CKEditor with updated content
          }}
          onClose={handleModalClose}
          annotationsCommon={{
              fill: '#ff0000',
          }}
          Text={{ text: 'Filerobot...' }}
          Rotate={{ angle: 90, componentType: 'slider' }}
          tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.RESIZE, TABS.PEN]}
          defaultTabId={TABS.ANNOTATE}
          defaultToolId={TOOLS.TEXT}
          closeAfterSave={true}
          onBeforeSave={()=> {
            return false
          }}
          />
          </div>
      </Modal>
      }   
    </div>
  );
};

export default Ckeditor;
